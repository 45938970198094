import { Roles } from "core/constants/Roles"
import { Token } from "core/models/Token"
import jwtDecode from "jwt-decode"


export function setToken(token: string) {
  localStorage.setItem('admin-token', token)
}

export function getToken() {
  return localStorage.getItem('admin-token') || ''
}

export function removeToken() {
  localStorage.removeItem('admin-token')
}

export function getDecodedToken() {
  const token = getToken()
  try {
    return jwtDecode<Token>(token)
  }
  catch {
    return null
  }
}

export function getRole() {
  const token = getDecodedToken()
  if (token) {
    const { role } = token
    return role
  }
  return Roles.UNKNOWN
}

export function getRoleForAuth() {
  let verifiedManagers: any = [1138582, 1097304]
  const token = getDecodedToken()
  if (token) {
    const { role, id } = token
    if (role === 'admin' && verifiedManagers.includes(id)) {
      return Roles.MANAGER
    }
    return role
  }
  return Roles.UNKNOWN
}

export function clearUser() {
  removeToken()
}