import { CATEGORY_PRODUCT_LIST_ACTION } from "core/constants/Actions";
import { Action } from "core/models/ActionTypes";
import { XoProducts } from "core/models/XoShop";

const initialState: { products: XoProducts[], isLoading: boolean, count: number, currentPage: number, title?: string | null } = {
  products: [],
  isLoading: false,
  count: 0,
  currentPage: 0,
  title: null
}

interface ActionType extends Action {
  payload: {
    products: XoProducts[],
    count: number,
    currentPage: number,
    title?: string
  }
}

export default function productBasedOnCategoryReducer(state = initialState, action: ActionType) {
  switch (action.type) {
    case CATEGORY_PRODUCT_LIST_ACTION.REQUEST:
      return { ...state, isLoading: true }

    case CATEGORY_PRODUCT_LIST_ACTION.SUCCESS:
      const { products, count, currentPage, title } = action.payload
      return { ...state, products, count, currentPage, isLoading: false, title }

    case CATEGORY_PRODUCT_LIST_ACTION.FAILURE:
      return { ...state, ...initialState }

    default:
      return state
  }
}