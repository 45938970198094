import { lazy, Suspense } from "react"
import { Route, Switch, Redirect } from "react-router-dom"
import Layout from "Layout"
import PrivateRoutes from "./PrivateRoute"
import LoginPage from "pages/Login"
import { getRoleForAuth } from "core/services/Storage"
import { Roles } from "core/constants/Roles"
import SuspenseLoader from "./SuspenseLoader"
import PasswordReset from "pages/PasswordReset"
import Callback from "pages/Callback"
import ExternalLoginCallback from "pages/ExternalLoginCallback"

const Tournaments = lazy(() => import('pages/Tournaments'))
const TournamentDetail = lazy(() => import('pages/TournamentDetail'))
const Games = lazy(() => import('pages/Games'))
const GameDetails = lazy(() => import('pages/GameDetails'))
const AddTournament = lazy(() => import('pages/AddTournament'))
const Moderators = lazy(() => import('pages/Moderators'))
const Spaces = lazy(() => import('pages/Spaces'))
const AccessRequests = lazy(() => import('pages/AccessRequests'))
const EditTournament = lazy(() => import('pages/EditTournament'))
const SpaceDetail = lazy(() => import("pages/SpaceDetail"))
const CloneTournament = lazy(() => import("pages/CloneTournament"))
const Products = lazy(() => import("pages/Products"))
const RedeemRequests = lazy(() => import("pages/RedeemRequests"))
const Advertisements = lazy(() => import("pages/Advertisements"))
const Wallet = lazy(() => import('pages/Wallet'))
const PaymentHistory = lazy(() => import('pages/PaymentHistory'))
const PaymentRequest = lazy(() => import('pages/PaymentRequest'))
const WebSliderList = lazy(() => import('pages/WebSliderList'))
const XoShop = lazy(() => import('pages/XoShop'))
const Dashboard = lazy(() => import('pages/Dashboard'))
const ShopCategory = lazy(() => import('pages/ShopProducts'))
const AddXoProduct = lazy(() => import('pages/AddXoProduct'));
const AddSlider = lazy(() => import('pages/AddSlider'))
const EditSlider = lazy(() => import('pages/EditSlider'))
const AddGame = lazy(() => import('pages/AddNewGame'))
const UpdateGame = lazy(() => import('pages/UpdateGame'))
const AddSpace = lazy(() => import('pages/AddSpace'))
const EditSpace = lazy(() => import('pages/EditSpace'))
const AddSpaceProduct = lazy(() => import('pages/AddSpaceProduct'))
const EditSpaceProduct = lazy(() => import('pages/EditSpaceProduct'))
const CreateSpacePost = lazy(() => import('pages/CreateSpacePost'))
const AddModerator = lazy(() => import('pages/AddModerator'))
const EditXoProduct = lazy(() => import('pages/EditXoProduct'))
const Profile = lazy(() => import('pages/Profile'))
const SpacePostDetail = lazy(() => import('pages/SpacePostDetail'))
const FAQ = lazy(() => import('pages/FAQ'))
const ProductRedeem = lazy(() => import('pages/ProductRedeemRequests'))
const RilvalryZoneDetail = lazy(() => import('pages/RivalryZoneDetail'))
const RivalryZoneList = lazy(() => import("pages/RivalryZoneList"))
const AddRivalryZone = lazy(() => import("pages/AddRivalryZone"))
const EditRivalryZone = lazy(() => import("pages/EditRivalryZone"))
const CloneRivalryZone = lazy(() => import("pages/CloneRivalryZone"))
const Shorts = lazy(() => import('pages/Shorts'))
const Pinned = lazy(() => import('pages/ManagePins'))
const ProvisionalTournaments = lazy(() => import('pages/ProvisionalTournaments'))
const ProvisionalTournamentDetail = lazy(() => import('pages/ProvisionalTournamentDetail'))
const SpaceShorts = lazy(() => import('pages/SpaceShorts'))
const SpaceShortDetails = lazy(() => import('pages/SpaceShortDetails'))
const Coupons = lazy(() => import('pages/Coupons'))
const AddCoupon = lazy(() => import('pages/AddCoupon'))
const GameStore = lazy(() => import('pages/GameStore'))
const AddGameToStore = lazy(() => import('pages/AddGameToStore'))
const EditGameToStore = lazy(() => import('pages/EditGameToStore'))
const GameStoreDetail = lazy(() => import('pages/GameStoreDetail'))
const ProPassUsersList = lazy(() => import('pages/ProPassUsersList'))
const PushNotification = lazy(() => import('pages/PushNotification'))
const Creator = lazy(() => import('pages/Creators'))
const AddCreator = lazy(() => import('pages/AddCreator'))
const CreatorDetail = lazy(() => import('pages/CreatorDetail'))
const EditCreator = lazy(() => import('pages/EditCreator'))
const SpaceMemberShip = lazy(() => import('pages/SpaceMembership'))
const AddSpaceMemberShip = lazy(() => import('pages/AddSpaceMemberShip'))
const EditSpaceMemberShip = lazy(() => import('pages/EditSpaceMemberShip'))
const Challenges = lazy(() => import('pages/Challenges'))
const AddChallenge = lazy(() => import('pages/AddChallenge'))
const ChallengeDetail = lazy(() => import('pages/ChallengeDetail'))
const CloneChallenge = lazy(() => import('pages/CloneChallenge'))
const EditChallenge = lazy(() => import('pages/EditChallenge'))
const AdminWallet = lazy(() => import('pages/AdminWallet'))

const SpacePages = () => (
  <Switch>
    <PrivateRoutes exact path="/spaces/add-space" component={AddSpace} />
    <PrivateRoutes exact path="/spaces/:spaceId" component={SpaceDetail} />
    <PrivateRoutes exact path="/spaces/:spaceId/shorts" component={SpaceShorts} />
    <PrivateRoutes exact path="/spaces/:spaceId/shorts/:shortsId" component={SpaceShortDetails} />
    <PrivateRoutes exact path="/spaces/products/:spaceId" component={Products} />
    <PrivateRoutes exact path="/spaces/:spaceId/redeem-requests" component={RedeemRequests} />
    <PrivateRoutes exact path="/spaces" component={Spaces} />
    <PrivateRoutes exact path="/spaces/edit-space/:spaceId" component={EditSpace} />
    <PrivateRoutes exact path="/spaces/add-product/:spaceId" component={AddSpaceProduct} />
    <PrivateRoutes exact path="/spaces/product/edit-product/:productId" component={EditSpaceProduct} />
    <PrivateRoutes exact path="/spaces/create-post/:spaceId" component={CreateSpacePost} />
    <PrivateRoutes exact path="/spaces/space-membership/:spaceId" component={SpaceMemberShip} />
    <PrivateRoutes exact path="/spaces/add-membership/:spaceId" component={AddSpaceMemberShip} />
    <PrivateRoutes exact path="/spaces/edit-membership/:spaceId" component={EditSpaceMemberShip} />
    <PrivateRoutes exact path="/spaces/:spaceId/:postId" component={SpacePostDetail} />
  </Switch>
)

const AdvertisementPages = () => (
  <Switch>
    <PrivateRoutes exact path="/advertisements" component={Advertisements} />
  </Switch>
)

const FAQPage = () => (
  <Switch>
    <PrivateRoutes exact path="/FAQ" component={FAQ} />
  </Switch>
)

const ModeratorPages = () => (
  <Switch>
    <PrivateRoutes exact path="/moderators" component={Moderators} />
    <PrivateRoutes exact path="/moderators/add-moderator" component={AddModerator} />
  </Switch>
)

const TournamentPages = () => (
  <Switch>
    <PrivateRoutes exact path="/tournaments/edit/:tournamentId" component={EditTournament} />
    <PrivateRoutes exact path="/tournaments/clone/:tournamentId" component={CloneTournament} />
    <PrivateRoutes exact path="/tournaments/add" component={AddTournament} />
    <PrivateRoutes exact path="/tournaments/details/:tournamentId" component={TournamentDetail} />
    <PrivateRoutes exact path="/tournaments" component={Tournaments} />
  </Switch>
)

const RivalryPages = () => (
  <Switch>
    <PrivateRoutes exact path="/rivalry-zone" component={RivalryZoneList} />
    <PrivateRoutes exact path="/rivalry-zone/details/:tournamentId" component={RilvalryZoneDetail} />
    <PrivateRoutes exact path="/rivalry-zone/edit/:tournamentId" component={EditRivalryZone} />
    <PrivateRoutes exact path="/rivalry-zone/clone/:tournamentId" component={CloneRivalryZone} />
    <PrivateRoutes exact path="/rivalry-zone/add-rivalry-zone" component={AddRivalryZone} />
  </Switch>
)

const GamePages = () => (
  <Switch>
    <PrivateRoutes exact path="/games/details/:gameId" component={GameDetails} />
    <PrivateRoutes exact path="/games/update/:gameId" component={UpdateGame} />
    <PrivateRoutes exact path="/games/add" component={AddGame} />
    <PrivateRoutes exact path="/games" component={Games} />
  </Switch>
)

const WalletPages = () => (
  <Switch>
    <PrivateRoutes exact path="/wallet/payment-history" component={PaymentHistory} />
    <PrivateRoutes exact path="/wallet/payment-request" component={PaymentRequest} />
    <PrivateRoutes exact path="/wallet" component={Wallet} />
  </Switch>
)

const UserManagementPages = () => (
  <Switch>
    <PrivateRoutes exact path="/user/access-request" component={AccessRequests} />
  </Switch>
)

const WebSliderPages = () => (
  <Switch>
    <PrivateRoutes exact path="/slider" component={WebSliderList} />
    <PrivateRoutes exact path="/slider/edit-slider/:bannerId" component={EditSlider} />
    <PrivateRoutes exact path="/slider/add-slider/" component={AddSlider} />
  </Switch>
)

const XoShopPages = () => (
  <Switch>
    <PrivateRoutes exact path="/xo-shop/redeem-requests" component={ProductRedeem} />
    <PrivateRoutes exact path="/xo-shop" component={XoShop} />
    <PrivateRoutes exact path="/xo-shop/product-category/:categoryId" component={ShopCategory} />
    <PrivateRoutes exact path="/xo-shop/add-product" component={AddXoProduct} />
    <PrivateRoutes exact path="/xo-shop/edit-product/:productId" component={EditXoProduct} />
    <PrivateRoutes exact path="/xo-shop/coupons" component={Coupons} />
    <PrivateRoutes exact path="/xo-shop/add-coupons" component={AddCoupon} />
  </Switch>
)

const DashboardPage = () => (
  <Switch>
    <PrivateRoutes exact path="/dashboard" component={Dashboard} />
  </Switch>
)

const ProfilePages = () => (
  <Switch>
    <PrivateRoutes exact path="/profile" component={Profile} />
  </Switch>
)

const ShortsPages = () => (
  <Switch>
    <PrivateRoutes exact path="/shorts" component={Shorts} />
  </Switch>
)

const PinnedPages = () => (
  <Switch>
    <PrivateRoutes exact path="/pinned" component={Pinned} />
  </Switch>
)

const ProvisionalTournamentPages = () => (
  <Switch>
    <PrivateRoutes exact path="/provisional-tournaments/:tournamentId" component={ProvisionalTournamentDetail} />
    <PrivateRoutes exact path="/provisional-tournaments" component={ProvisionalTournaments} />
  </Switch>
)

const GameStorePages = () => (
  <Switch>
    <PrivateRoutes exact path='/game-store' component={GameStore} />
    <PrivateRoutes exact path='/game-store/add' component={AddGameToStore} />
    <PrivateRoutes exact path='/game-store/detail/:gameStoreId' component={GameStoreDetail} />
    <PrivateRoutes exact path='/game-store/edit/:gameStoreId' component={EditGameToStore} />
  </Switch>
)

const ProPassPages = () => (
  <Switch>
    <PrivateRoutes exact path='/pro-pass/users' component={ProPassUsersList} />
  </Switch>
)

const PushNotificationPages = () => (
  <Switch>
    <PrivateRoutes exact path='/push-notification' component={PushNotification} />
  </Switch>
)

const CreatorPages = () => (
  <Switch>
    <PrivateRoutes exact path='/creators' component={Creator} />
    <PrivateRoutes exact path='/creators/add' component={AddCreator} />
    <PrivateRoutes exact path='/creators/detail/:creatorId' component={CreatorDetail} />
    <PrivateRoutes exact path='/creators/edit/:creatorId' component={EditCreator} />
  </Switch>
)

const ChallengePages = () => (
  <Switch>
    <PrivateRoutes exact path='/challenges/clone/:challengeId' component={CloneChallenge} />
    <PrivateRoutes exact path="/challenges/edit/:tournamentId" component={EditChallenge} />
    <PrivateRoutes exact path='/challenges' component={Challenges} />
    <PrivateRoutes exact path='/challenges/details/:challengeId' component={ChallengeDetail} />
    <PrivateRoutes exact path='/challenges/add' component={AddChallenge} />
  </Switch>
)

const AdminWalletPages = () => (
  <Switch>
    <PrivateRoutes exact path='/admin-wallet' component={AdminWallet} />
  </Switch>
)

const RBAC_ROUTES = [
  {
    path: '/spaces',
    component: SpacePages,
    canAccess: [Roles.HUB_ADMIN, Roles.SUPER_ADMIN]
  },
  {
    path: '/moderators',
    component: ModeratorPages,
    canAccess: [Roles.HUB_ADMIN, Roles.SUPER_ADMIN, Roles.MANAGER]
  },
  {
    path: '/tournaments',
    component: TournamentPages,
    canAccess: [Roles.HUB_ADMIN, Roles.SUPER_ADMIN]
  },
  {
    path: '/rivalry-zone',
    component: RivalryPages,
    canAccess: [Roles.HUB_ADMIN, Roles.SUPER_ADMIN]
  },
  {
    path: '/games',
    component: GamePages,
    canAccess: [Roles.SUPER_ADMIN]
  },
  {
    path: '/wallet',
    component: WalletPages,
    canAccess: [Roles.HUB_ADMIN, Roles.SUPER_ADMIN]
  },
  {
    path: '/user',
    component: UserManagementPages,
    canAccess: [Roles.SUPER_ADMIN, Roles.MANAGER]
  },
  {
    path: '/advertisements',
    component: AdvertisementPages,
    canAccess: [Roles.SUPER_ADMIN, Roles.MANAGER]
  },
  {
    path: '/slider',
    component: WebSliderPages,
    canAccess: [Roles.SUPER_ADMIN]
  },
  {
    path: '/xo-shop',
    component: XoShopPages,
    canAccess: [Roles.SUPER_ADMIN, Roles.MANAGER]
  },
  {
    path: '/dashboard',
    component: DashboardPage,
    canAccess: [Roles.SUPER_ADMIN, Roles.HUB_ADMIN]
  },
  {
    path: '/profile',
    component: ProfilePages,
    canAccess: [Roles.SUPER_ADMIN, Roles.HUB_ADMIN, Roles.MANAGER]
  },
  {
    path: '/FAQ',
    component: FAQPage,
    canAccess: [Roles.SUPER_ADMIN, Roles.HUB_ADMIN]
  },
  {
    path: '/shorts',
    component: ShortsPages,
    canAccess: [Roles.SUPER_ADMIN, Roles.MANAGER]
  },
  {
    path: '/pinned',
    component: PinnedPages,
    canAccess: [Roles.SUPER_ADMIN, Roles.MANAGER]
  },
  {
    path: '/provisional-tournaments',
    component: ProvisionalTournamentPages,
    canAccess: [Roles.SUPER_ADMIN, Roles.MANAGER]
  },
  {
    path: '/game-store',
    component: GameStorePages,
    canAccess: [Roles.SUPER_ADMIN, Roles.MANAGER]
  },
  {
    path: '/pro-pass/users',
    component: ProPassPages,
    canAccess: [Roles.SUPER_ADMIN, Roles.MANAGER]
  },
  {
    path: '/push-notification',
    component: PushNotificationPages,
    canAccess: [Roles.SUPER_ADMIN, Roles.MANAGER]
  },
  {
    path: '/creators',
    component: CreatorPages,
    canAccess: [Roles.SUPER_ADMIN, Roles.HUB_ADMIN, Roles.MANAGER]
  },
  {
    path: '/challenges',
    component: ChallengePages,
    canAccess: [Roles.SUPER_ADMIN, Roles.HUB_ADMIN]
  },
  {
    path: '/admin-wallet',
    component: AdminWalletPages,
    canAccess: [Roles.SUPER_ADMIN, Roles.HUB_ADMIN]
  },
]

const RedirectLogin = () => <Redirect to="/login" />
const WrappedRoutes = () => (
  <Layout>
    <Suspense fallback={<SuspenseLoader />}>
      {
        RBAC_ROUTES.filter(route => route.canAccess.includes(getRoleForAuth()))
          .map(perimtedRoutes => {
            return <PrivateRoutes
              key={perimtedRoutes.path}
              path={perimtedRoutes.path}
              component={perimtedRoutes.component}
            />
          })
      }
    </Suspense>
  </Layout>
)

const Router = () => (
  <Switch>
    <Route exact path="/" component={RedirectLogin} />
    <Route exact path="/login" component={LoginPage} />
    <Route exact path="/password-reset" component={PasswordReset} />
    <Route exact path="/user/login/callback" component={Callback} />
    <Route exact path="/user/login/external/callback" component={ExternalLoginCallback} />
    <PrivateRoutes path="/" component={WrappedRoutes} />
  </Switch>
)

export default Router