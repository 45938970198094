export interface Action {
  REQUEST: string;
  SUCCESS: string;
  FAILURE: string;
}

export interface ResetAction extends Action {
  RESET: string;
}

export const LOADER_ACTION = {
  SET: 'SET_LOADER',
  CLEAR: 'CLEAR_LOADER'
}

export const TOURNAMENT_LIST_ACTION: Action = {
  FAILURE: 'TOURNAMENT_LIST_FAILURE',
  REQUEST: 'TOURNAMENT_LIST_REQUEST',
  SUCCESS: 'TOURNAMENT_LIST_SUCCESS'
}

export const RIVALRY_ZONE_LIST_ACTION: Action = {
  FAILURE: 'RIVALRY_ZONE_LIST_FAILURE',
  REQUEST: 'RIVALRY_ZONE_LIST_REQUEST',
  SUCCESS: 'RIVALRY_ZONE_LIST_SUCCESS'
}

export const GAME_LIST_ACTION: Action = {
  FAILURE: 'GAME_LIST_FAILURE',
  REQUEST: 'GAME_LIST_REQUEST',
  SUCCESS: 'GAME_LIST_SUCCESS'
}

export const GAME_DETAILS_ACTION: ResetAction = {
  FAILURE: 'GAME_DETAILS_FAILURE',
  REQUEST: 'GAME_DETAILS_REQUEST',
  SUCCESS: 'GAME_DETAILS_SUCCESS',
  RESET: 'GAME_DETAILS_RESET'
}

export const TOURNAMENT_DETAILS_ACTION: ResetAction = {
  FAILURE: 'TOURNAMENT_DETAILS_FAILURE',
  REQUEST: 'TOURNAMENT_DETAILS_REQUEST',
  SUCCESS: 'TOURNAMENT_DETAILS_SUCCESS',
  RESET: 'TOURNAMENT_DETAILS_RESET'
}

export const RIVALRY_ZONE_DETAILS_ACTION: ResetAction = {
  FAILURE: 'RIVALRY_ZONE_DETAILS_FAILURE',
  REQUEST: 'RIVALRY_ZONE_DETAILS_REQUEST',
  SUCCESS: 'RIVALRY_ZONE_DETAILS_SUCCESS',
  RESET: 'RIVALRY_ZONE_DETAILS_RESET'
}

export const SPACE_LIST_ACTION: Action = {
  FAILURE: 'SPACE_LIST_FAILURE',
  REQUEST: 'SPACE_LIST_REQUEST',
  SUCCESS: 'SPACE_LIST_SUCCESS'
}

export const DASHBOARD_DETAILS_ACTION: Action = {
  FAILURE: 'DASHBOARD_DETAILS_FAILURE',
  REQUEST: 'DASHBOARD_DETAILS_REQUEST',
  SUCCESS: 'DASHBOARD_DETAILS_SUCCESS'
}

export const SPACE_POST_LIST_ACTION: Action = {
  FAILURE: 'SPACE_POST_LIST_FAILURE',
  REQUEST: 'SPACE_POST_LIST_REQUEST',
  SUCCESS: 'SPACE_POST_LIST_SUCCESS'
}

export const SPACE_DEATILS_ACTION: ResetAction = {
  FAILURE: 'SPACE_DEATILS_FAILURE',
  REQUEST: 'SPACE_DEATILS_REQUEST',
  SUCCESS: 'SPACE_DEATILS_SUCCESS',
  RESET: 'SPACE_DEATILS_RESET'
}

export const MODERATOR_LIST_ACTION: Action = {
  FAILURE: 'MODERATOR_LIST_FAILURE',
  REQUEST: 'MODERATOR_LIST_REQUEST',
  SUCCESS: 'MODERATOR_LIST_SUCCESS'
}

export const USER_ACCESS_REQUEST_LIST_ACTION: Action = {
  FAILURE: 'USER_ACCESS_REQUEST_LIST_FAILURE',
  REQUEST: 'USER_ACCESS_REQUEST_LIST_REQUEST',
  SUCCESS: 'USER_ACCESS_REQUEST_LIST_SUCCESS'
}

export const SPACE_PRODUCTS_ACTION: Action = {
  FAILURE: 'SPACE_PRODUCTS_FAILURE',
  REQUEST: 'SPACE_PRODUCTS_REQUEST',
  SUCCESS: 'SPACE_PRODUCTS_SUCCESS',
}

export const WITHDRAWAL_REQUEST_ACTION: ResetAction = {
  FAILURE: 'WITHDRAWAL_REQUEST_FAILURE',
  REQUEST: 'WITHDRAWAL_REQUEST_REQUEST',
  SUCCESS: 'WITHDRAWAL_REQUEST_SUCCESS',
  RESET: 'WITHDRAWAL_REQUEST_RESET'
}

export const TRANSACTION_REQUEST_ACTION: Action = {
  FAILURE: 'TRANSACTION_REQUEST_FAILURE',
  REQUEST: 'TRANSACTION_REQUEST_REQUEST',
  SUCCESS: 'TRANSACTION_REQUEST_SUCCESS',
}

export const REDEEM_REQUEST_ACTION: Action = {
  FAILURE: 'REDEEM_REQUEST_FAILURE',
  REQUEST: 'REDEEM_REQUEST_REQUEST',
  SUCCESS: 'REDEEM_REQUEST_SUCCESS',
}

export const BANNER_LIST_ACTION: Action = {
  FAILURE: 'BANNER_LIST_FAILURE',
  REQUEST: 'BANNER_LIST_REQUEST',
  SUCCESS: 'BANNER_LIST_SUCCESS',
}

export const BANNER_DETAILS_ACTION: ResetAction = {
  FAILURE: 'BANNER_DETAILS_FAILURE',
  REQUEST: 'BANNER_DETAILS_REQUEST',
  SUCCESS: 'BANNER_DETAILS_SUCCESS',
  RESET: 'BANNER_DETAILS_RESET'
}

// XOShop constants
export const PRODUCT_LIST_ACTION: Action = {
  FAILURE: 'PRODUCT_LIST_FAILURE',
  REQUEST: 'PRODUCT_LIST_REQUEST',
  SUCCESS: 'PRODUCT_LIST_SUCCESS',
}

export const CATEGORY_LIST_ACTION: Action = {
  FAILURE: 'CATEGORY_LIST_FAILURE',
  REQUEST: 'CATEGORY_LIST_REQUEST',
  SUCCESS: 'CATEGORY_LIST_SUCCESS',
}

export const PRODUCT_REDEEM_REQUEST_LIST_ACTION: Action = {
  FAILURE: 'PRODUCT_REDEEM_REQUEST_LIST_FAILURE',
  REQUEST: 'PRODUCT_REDEEM_REQUEST_LIST_REQUEST',
  SUCCESS: 'PRODUCT_REDEEM_REQUEST_LIST_SUCCESS',
}

export const PRODUCT_DETAIL_ACTION: Action = {
  FAILURE: 'PRODUCT_DETAIL_ACTION_FAILURE',
  REQUEST: 'PRODUCT_DETAIL_ACTION_REQUEST',
  SUCCESS: 'PRODUCT_DETAIL_ACTION_SUCCESS'
}

export const CATEGORY_PRODUCT_LIST_ACTION: Action = {
  FAILURE: 'CATEGORY_PRODUCT_LIST_FAILURE',
  REQUEST: 'CATEGORY_PRODUCT_LIST_REQUEST',
  SUCCESS: 'CATEGORY_PRODUCT_LIST_SUCCESS'
}

export const USER_DETAILS_ACTION: Action = {
  FAILURE: 'USER_DETAILS_FAILURE',
  REQUEST: 'USER_DETAILS_REQUEST',
  SUCCESS: 'USER_DETAILS_SUCCESS'
}

export const USER_ACCESS_GAME_REQUEST_LIST_ACTION: Action = {
  FAILURE: 'USER_ACCESS_GAME_REQUEST_LIST_FAILURE',
  REQUEST: 'USER_ACCESS_GAME_REQUEST_LIST_REQUEST',
  SUCCESS: 'USER_ACCESS_GAME_REQUEST_LIST_SUCCESS'
}

export const USER_ACCESS_GAME_STORE_REQUEST_LIST_ACTION: Action = {
  FAILURE: 'USER_ACCESS_GAME_STORE_REQUEST_LIST_FAILURE',
  REQUEST: 'USER_ACCESS_GAME_STORE_REQUEST_LIST_REQUEST',
  SUCCESS: 'USER_ACCESS_GAME_STORE_REQUEST_LIST_SUCCESS'
}

export const SPACE_POST_DETAILS_ACTION: ResetAction = {
  FAILURE: 'SPACE_POST_DETAILS_FAILURE',
  REQUEST: 'SPACE_POST_DETAILS_REQUEST',
  SUCCESS: 'SPACE_POST_DETAILS_SUCCESS',
  RESET: 'SPACE_POST_DETAILS_RESET'
}

export const CAMPAIGN_LINK_ACTION: Action = {
  FAILURE: 'CAMPAIGN_LINK_FAILURE',
  REQUEST: 'CAMPAIGN_LINK_REQUEST',
  SUCCESS: 'CAMPAIGN_LINK_SUCCESS'
}

export const SHORTS_LIST_ACTION: Action = {
  FAILURE: 'SHORTS_LIST_FAILURE',
  REQUEST: 'SHORTS_LIST_REQUEST',
  SUCCESS: 'SHORTS_LIST_SUCCESS'
}

export const PINNED_TOURNAMENT_LIST_ACTION: Action = {
  FAILURE: 'PINNED_TOURNAMENT_LIST_FAILURE',
  REQUEST: 'PINNED_TOURNAMENT_LIST_REQUEST',
  SUCCESS: 'PINNED_TOURNAMENT_LIST_SUCCESS'
}

export const PINNED_SPACE_POST_LIST_ACTION: Action = {
  FAILURE: 'PINNED_SPACE_POST_LIST_FAILURE',
  REQUEST: 'PINNED_SPACE_POST_LIST_REQUEST',
  SUCCESS: 'PINNED_SPACE_POST_LIST_SUCCESS'
}

export const SPACE_SHORTS_LIST_ACTION: Action = {
  FAILURE: 'SPACE_SHORTS_LIST_FAILURE',
  REQUEST: 'SPACE_SHORTS_LIST_REQUEST',
  SUCCESS: 'SPACE_SHORTS_LIST_SUCCESS'
}

export const SPACE_SHORTS_DETAILS_ACTION: Action = {
  FAILURE: 'SPACE_SHORTS_DETAILS_FAILURE',
  REQUEST: 'SPACE_SHORTS_DETAILS_REQUEST',
  SUCCESS: 'SPACE_SHORTS_DETAILS_SUCCESS'
}

export const SPACE_SHORTS_COMMENTS_ACTION: Action = {
  FAILURE: 'SPACE_SHORTS_COMMENTS_FAILURE',
  REQUEST: 'SPACE_SHORTS_COMMENTS_REQUEST',
  SUCCESS: 'SPACE_SHORTS_COMMENTS_SUCCESS'
}

//  PROVISIONAL_TOURNAMENT Constants

export const PROVISIONAL_TOURNAMENT_LIST_ACTION: Action = {
  FAILURE: 'PROVISIONAL_TOURNAMENT_LIST_FAILURE',
  REQUEST: 'PROVISIONAL_TOURNAMENT_LIST_REQUEST',
  SUCCESS: 'PROVISIONAL_TOURNAMENT_LIST_SUCCESS'
}

export const PROVISIONAL_TOURNAMENT_DETAILS_ACTION: ResetAction = {
  FAILURE: 'PROVISIONAL_TOURNAMENT_DETAILS_FAILURE',
  REQUEST: 'PROVISIONAL_TOURNAMENT_DETAILS_REQUEST',
  SUCCESS: 'PROVISIONAL_TOURNAMENT_DETAILS_SUCCESS',
  RESET: 'PROVISIONAL_TOURNAMENT_DETAILS_RESET'
}

// Coupons Constants - XoSHOP

export const COUPONS_LIST_ACTION: Action = {
  FAILURE: 'COUPONS_LIST_FAILURE',
  REQUEST: 'COUPONS_LIST_REQUEST',
  SUCCESS: 'COUPONS_LIST_SUCCESS'
}

// Game Store

export const GAME_STORE_LIST_ACTION: Action = {
  FAILURE: 'GAME_STORE_LIST_FAILURE',
  REQUEST: 'GAME_STORE_LIST_REQUEST',
  SUCCESS: 'GAME_STORE_LIST_SUCCESS'
}

export const GAME_STORE_DETAILS_ACTION: ResetAction = {
  FAILURE: 'GAME_STORE_DETAILS_FAILURE',
  REQUEST: 'GAME_STORE_DETAILS_REQUEST',
  SUCCESS: 'GAME_STORE_DETAILS_SUCCESS',
  RESET: 'GAME_STORE_DETAILS_RESET'
}

// Pro-Pass
export const PRO_PASS_USER_LIST: Action = {
  FAILURE: 'PRO_PASS_USER_LIST_FAILURE',
  REQUEST: 'PRO_PASS_USER_LIST_REQUEST',
  SUCCESS: 'PRO_PASS_USER_LIST_SUCCESS',
}

// Creator
export const CREATOR_LIST_ACTION: ResetAction = {
  FAILURE: 'CREATOR_LIST_FAILURE',
  REQUEST: 'CREATOR_LIST_REQUEST',
  SUCCESS: 'CREATOR_LIST_SUCCESS',
  RESET: 'CREATOR_LIST_RESET',
}

// Toggle Theme
export const TOGGLE_THEME_ACTION: Action = {
  REQUEST: 'TOGGLE_THEME_REQUEST',
  FAILURE: 'TOGGLE_THEME_FAILURE',
  SUCCESS: 'TOGGLE_THEME_SUCCESS',
}

export const ENABLE_TOGGLE_SWITCH_ACTION: Action = {
  REQUEST: 'ENABLE_TOGGLE_SWITCH_REQUEST',
  FAILURE: 'ENABLE_TOGGLE_SWITCH_FAILURE',
  SUCCESS: 'ENABLE_TOGGLE_SWITCH_SUCCESS',
}

//Space Subscription
export const SPACE_SUBSCRIPTION_ACTION: ResetAction = {
  FAILURE: 'SPACE_SUBSCRIPTION_FAILURE',
  REQUEST: 'SPACE_SUBSCRIPTION_REQUEST',
  SUCCESS: 'SPACE_SUBSCRIPTION_SUCCESS',
  RESET: 'SPACE_SUBSCRIPTION_RESET',
}
export const SPACE_SUBSCRIPTION_GRAPH_ACTION: ResetAction = {
  FAILURE: 'SPACE_SUBSCRIPTION_GRAPH_FAILURE',
  REQUEST: 'SPACE_SUBSCRIPTION_GRAPH_REQUEST',
  SUCCESS: 'SPACE_SUBSCRIPTION_GRAPH_SUCCESS',
  RESET: 'SPACE_SUBSCRIPTION_GRAPH_RESET',
}

// Challenges
export const CHALLENGE_DETAILS_ACTION: ResetAction = {
  FAILURE: 'CHALLENGE_DETAILS_FAILURE',
  REQUEST: 'CHALLENGE_DETAILS_REQUEST',
  SUCCESS: 'CHALLENGE_DETAILS_SUCCESS',
  RESET: 'CHALLENGE_DETAILS_RESET'
}

export const CHALLENGE_LIST_ACTION: Action = {
  FAILURE: 'CHALLENGE_LIST_FAILURE',
  REQUEST: 'CHALLENGE_LIST_REQUEST',
  SUCCESS: 'CHALLENGE_LIST_SUCCESS'
}

// Wallet
export const USER_WALLET_DETAILS_ACTION: ResetAction = {
  FAILURE: 'USER_WALLET_DETAILS_FAILURE',
  REQUEST: 'USER_WALLET_DETAILS_REQUEST',
  SUCCESS: 'USER_WALLET_DETAILS_SUCCESS',
  RESET: 'USER_WALLET_DETAILS_RESET'
}