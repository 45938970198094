const emailRe = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
const urlRe = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/

export function isEmail(email: string) {
  if (!email) return false
  return emailRe.test(email.toLowerCase());
}

export function isUrl(url: string) {
  if (!url) return false
  return urlRe.test(url)
}

export function sanitizeUrl(url: string) {
  if (url.startsWith('http')) {
    return url
  }
  return `https://${url}`
}